import { render, staticRenderFns } from "./PInventoryCommission.vue?vue&type=template&id=cc6d7702&scoped=true"
import script from "./PInventoryCommission.vue?vue&type=script&lang=js"
export * from "./PInventoryCommission.vue?vue&type=script&lang=js"
import style0 from "./PInventoryCommission.vue?vue&type=style&index=0&id=cc6d7702&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc6d7702",
  null
  
)

export default component.exports