<template>
  <div 
    class="inline-block px-4 py-1 font-semibold rounded-full" 
    :class="statusClass"
    v-html="statusText"
  ></div>
</template>

<script>
export default {

  name: "p-status-label",

  props: {
    status: {
      type: String,
      default: ""
    }
  },

  computed: {

    statusText () {
      return this.$tk(`Common.SupportStatuses.${this.status}`)
    },

    statusClass () {
      return this.status === "New"     ? "text-yellow-900 bg-yellow-300" : 
             this.status === "Open"    ? "text-red-100 bg-red-500" :
             this.status === "Pending" ? "text-blue-100 bg-blue-500" : 
             this.status === "Closed"  ? "text-gray-100 bg-gray-500" : "text-gray-100 bg-gray-400" 
    }

  }
}
</script>