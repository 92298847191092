<template>
  <div>
    
    <PWidgetHeader icon="newspaper">
      <PTranslation k="PMessageWidget.News" />
    </PWidgetHeader>

    <PLoader 
      class="mt-2" 
      v-if="isLoading" 
    />

    <template v-else>

      <div class="mt-2">
        
        <div class="mt-4" v-for="(message, index) in messages" :key="index">          
          <RouterLink 
            class="group"
            :to="{ name: 'messages.Messages', params: { id: message.id } }"
          >
            <h2 class="text-sm font-semibold text-green-600 underline group-hover:text-black">{{ message.title }}</h2>          
            <div class="text-xs">{{ message.created | date }} / {{ message.createrName }}</div>
            <p class="mt-1 text-sm">{{ message.abstract }}</p>

          </RouterLink>                  
        </div>    

        <div class="flex justify-end mt-4">
          <RouterLink 
            class="flex items-center text-sm text-green-600 underline hover:text-black" 
            :to="{ name: 'messages.Messages' }"
          >
            <div>
              {{ $tk("PMessageWidget.ShowAllNews", true) }}
            </div>
            <FontAwesomeIcon
              class="ml-2"
              :icon="['far', 'arrow-right']" 
            />
          </RouterLink>
        </div>
      </div>

    </template>

  </div>
</template>

<script>

import http from "@/http"
import PWidgetHeader from "./PWidgetHeader"

export default {
  // POST MessageRead
  // også filter på list for å bare se viktige, mustRead, isRead

  components: {
    PWidgetHeader
  },

  data () {
    return {
      messages: [],
      isLoading: false
    }
  },

  methods: {

    handleError (error) {
      this.$store.dispatch("notify", {
        type: "negative",
        text: error.reason
      })
    },

    async getMessages () {
      this.isLoading = true
      try {
        const messages = await http.get("messages", {
          params: {
            type: "Message"
          }
        })
        
        if (messages.length > 0) {
          this.messages = [ messages[0] ]
        }

      } catch (error) {
        this.handleError(error)
      }
      this.isLoading = false
    }
  },

  async created () {
    await this.getMessages()
  }

  // watch: {
  //   location: {
  //     handler: async function () {
  //       await this.getMessages()
  //     },
  //     immediate: true
  //   }
  // }

}
</script>