<template>
  <div class="flex flex-row items-center justify-center flex-1 h-full lg:flex-col">
    
    <div class="w-1/2 lg:w-auto">
      <img       
        class="w-4/5 mx-auto"
        v-if="customer.image"
        :src="`${assetsBaseUrl}${customer.image}`"      
      />
    </div>

    <div class="flex flex-col items-center w-1/2 lg:w-auto">

      <div class="mt-4 text-center">
        {{ customer.name }}
      </div>

      <div class="flex flex-col items-center mt-4 text-sm space-y-1" v-if="superUser">      
        <div class="font-semibold">
          <PTranslation k="PCompanyWidget.Superuser" />
        </div>
        <div>{{ superUser.name }}</div>
        <a v-if="superUser.email" class="text-center underline break-all" :href="`mailto:${superUser.email}`">{{ superUser.email }}</a>
        <a v-if="superUser.phone" class="underline" :href="`tel:${superUser.phone}`">{{ superUser.phone }}</a>
      </div>
      
    </div>

  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  
  computed: {

    ...mapGetters([ "customer", "location" ]),

    superUser () {
      return this.location.superUser || this.customer.superUser
    },

    assetsBaseUrl () {
      return this.$appConfig.assetsBaseUrl
    },

  }
}
</script>