<template>
  <div>
    <PWidgetHeader icon="globe">
      <PTranslation k="PEnvironmentWidget.Environment" />
    </PWidgetHeader>

    <div class="flex flex-col mt-2 space-y-4">
      <highcharts :options="fillTrend"></highcharts>
      <highcharts :options="reuseTrend"></highcharts>
      <highcharts :options="emissionTrend"></highcharts>
    </div>
  </div>
</template>

<script>

import http from "@/http"
import { mapGetters } from "vuex"
import PWidgetHeader from "./PWidgetHeader"
import { Chart } from "highcharts-vue"
import { nb, sv, da, fi, enUS } from 'date-fns/locale'
import {
  forEach,
  map,
  min,
  upperFirst
} from "lodash"
import {
  eachMonthOfInterval,
  sub,
  format
} from "date-fns"

const MONTH_COUNT = 12

export default {

  components: {
    PWidgetHeader,
    highcharts: Chart
  },

  data() {
    return {
      isLoading: false,
      historyCust: [],
      historyAll: [],
      chartDefaults: {

      },
      lineChartDefaults: {
        chart: {
          height: 120,
          animation: false,
          style: {
            fontFamily: "Inter var"
          },
          spacing: [10, 5, 0, 0]
        },
        title: {
          align: "left",
          style: {
            fontSize: 13
          }
        },
        credits: {
          enabled: false
        },
        legend: {
          enabled: false
        },
        yAxis: {
          max: 100,
          // min: 0,
          labels: {
            formatter: function() {
              var label = this.axis.defaultLabelFormatter.call(this);
              return label + "%"
            }
          },
          title: {
            enabled: false
          }
        },
        xAxis: {
          categories: this.getMonths(),
        },
        plotOptions: {
          areaspline: {
            dataLabels: {
              enabled: false
            }
          }
        },
      },
      barChartDefaults: {
        chart: {
          height: 120,
          animation: false,
          style: {
            fontFamily: "Inter var"
          },
          spacing: [10, 5, 0, 0]
        },
        title: {
          align: "left",
          style: {
            fontSize: 13
          }
        },
        credits: {
          enabled: false
        },
        legend: {
          enabled: false
        },
        yAxis: {
          title: {
            enabled: false
          }
        },
        xAxis: {
          categories: this.getMonths(),
        },
        plotOptions: {
          areaspline: {
            dataLabels: {
              enabled: false
            }
          }
        },
      }
    }
  },

  computed: {

    ...mapGetters(["customer", "location"]),

    fillTrend() {

      const all = this.getTrend(this.historyAll, "volReceived", "volTruck") // "qtyConfirmed", "qtyMaxTruck"
      const cust = this.getTrend(this.historyCust, "volReceived", "volTruck")
      let minValue = min(all.concat(cust)) | 0
      minValue = minValue === 100 ? 0 : minValue

      return {
        ...this.lineChartDefaults,
        yAxis: {
          ...this.lineChartDefaults.yAxis,
          min: minValue
        },
        title: {
          ...this.lineChartDefaults.title,
          text: this.$t("PEnvironmentWidget.FillDegreeLastMonths", { count: MONTH_COUNT }),
        },
        series: [
          {
            name: this.$t("PEnvironmentWidget.AverageAllCustomers"),
            type: "areaspline",
            color: "#cccccc",
            data: all
          },
          {
            name: this.customer.name,
            type: "areaspline",
            color: "#13B13F",
            data: cust
          }
        ]
      }
    },

    reuseTrend() {
      const all = this.getTrend(this.historyAll, "volReused", "volReceived")  //"qtyReused", "qtyReceived"
      const cust = this.getTrend(this.historyCust, "volReused", "volReceived")
      let minValue = min(all.concat(cust)) | 0
      minValue = minValue === 100 ? 0 : minValue

      return {
        ...this.lineChartDefaults,
        yAxis: {
          ...this.lineChartDefaults.yAxis,
          min: minValue
        },
        title: {
          ...this.lineChartDefaults.title,
          text: this.$t("PEnvironmentWidget.ReuseDegreeLastMonths", { count: MONTH_COUNT }),
        },
        series: [
          {
            name: this.$t("PEnvironmentWidget.AverageAllCustomers"),
            type: "areaspline",
            color: "#cccccc",
            data: all
          },
          {
            name: this.customer.name,
            type: "areaspline",
            color: "#13B13F",
            data: cust
          }
        ]
      }
    },

    emissionTrend() {
      // const all = this.historyAll.map(h => Math.round(Math.abs(h?.co2ReuseEffect ?? 0)))
      const cust = this.historyCust.map(h => Math.round(Math.abs(h?.bigUseEffect ?? 0) + Math.abs(h?.co2Emissions ?? 0)))

      return {
        ...this.barChartDefaults,
        yAxis: {
          ...this.barChartDefaults.yAxis,
          max: Math.max(...cust)
        },
        title: {
          ...this.barChartDefaults.title,
          text: this.$t("PEnvironmentWidget.co2Emissions", { count: MONTH_COUNT }),
        },
        series: [
          // {
          //   name: this.$t("PEnvironmentWidget.AverageAllCustomers"),
          //   type: "column",
          //   color: "#cccccc",
          //   data: all
          // },
          {
            name: this.customer.name,
            type: "column",
            color: "#13B13F",
            data: cust
          }
        ]
      }
    },

  },

  methods: {

    yyyyMM(h) {
      return h !== null ? `${h.year}${(h.month < 10 ? '0' + h.month : h.month)}` : ""
    },

    numberFormat(number) {
      return new Intl.NumberFormat(this.$i18n.locale.replace('-sw', '')).format(number)
    },

    async init() {
      await Promise.all([this.getEnvironmentHistory()])
    },

    async getEnvironmentHistory() {
      this.isLoading = true

      try {
        const [historyCust, historyAll] = await Promise.all([
          http.get("EnvironmentHistory", { params: { customerId: this.customer.id } }),
          http.get("EnvironmentHistory", { params: { customerId: "*" } })
        ])

        this.historyCust = this.getHistoryRange(historyCust)
        this.historyAll = this.getHistoryRange(historyAll)

      } catch (error) {
        this.$appInsights.trackException({ exception: error })
      }

      this.isLoading = false
    },

    getRange() {
      return eachMonthOfInterval({
        start: sub(new Date(), { months: MONTH_COUNT }),
        end: sub(new Date(), { months: 1 })
      })
    },

    getMonths() {
      const locale = this.$i18n.locale === "nb-NO" ? nb :
        this.$i18n.locale === "sv-SE" ? sv :
          this.$i18n.locale === "da-DK" ? da :
            this.$i18n.locale === "fi-FI" ? fi : enUS

      return map(this.getRange(), r => {
        return upperFirst(format(r, "LLL", { locale }))
          .replace(".", "")
          .substr(0, 3)
      })
    },

    getHistoryRange(history) {

      let arr = []

      forEach(this.getRange(), m => {
        let added = false
        forEach(history, c => {
          if (m.getFullYear() === c.year && (m.getMonth() + 1) === c.month) {
            arr.push(c)
            added = true
          }
        })
        if (!added) {
          arr.push(null)
        }
      })

      return arr
    },

    getTrend(arr, quotient, dividend) {

      let trend = []

      for (let i = 0; i < arr.length; i++) {
        let t = null
        let r = arr[i]
        if (r) {
          let q = r[quotient] || 0
          let d = r[dividend] || 0

          if (q === 0 && d === 0) {
            // undefined
            t = null
          } else if (d === 0) {
            // missing
            t = 100
          } else {
            t = parseInt((q / d) * 100, 10)
            // overflow
            if (t > 100) {
              t = 100
            }
          }
        }
        trend.push(t)
      }

      // fill empty slots with previous value
      for (let i = 0; i < trend.length; i++) {
        if (trend[i] === null && i > 0) {
          trend[i] = trend[i - 1]
        }
      }

      return trend
    },

  },

  async created() {
    await this.init()
  }

}
</script>