<template>
  <AreaContainer>
    <div class="flex-1 w-full p-4 overflow-y-auto shadow-inner">

      <div class="lg:flex space-y-4 lg:space-y-0 lg:space-x-4">
        
        <!-- left area -->
        <div class="flex-col space-y-4 lg:w-2/3">
        
          <!-- left first -->
          <div class="lg:flex space-y-4 lg:space-y-0 lg:space-x-4">

            <div class="p-4 overflow-hidden bg-white rounded shadow lg:w-1/3">
              <PCompanyWidget />
            </div>

            <div class="p-4 bg-white rounded shadow lg:w-2/3">
              <PEnvironmentWidget />
            </div>

          </div>          

          <!-- left second -->
          
          <div class="p-4 bg-white rounded shadow">
            <POrdersWidget />            
          </div>

          <!-- left third -->

          <div class="p-4 bg-white rounded shadow" v-if="location.poolingGroupId">
            <PInventoryPooling />
          </div>

          <!-- left fourth -->

          <div class="p-4 bg-white rounded shadow" v-if="location.isCommission && !location.isSmartRetur">
            <PInventoryCommission />            
          </div>

        </div>

        <!--  right -->
        <div class="flex-col lg:w-1/3 space-y-4">
          
          <div class="p-4 bg-white rounded shadow">            
            <PVolumeWidget />
          </div>

          <div class="p-4 bg-white rounded shadow">            
            <PMessageWidget />
          </div>

          <div class="p-4 bg-white rounded shadow">
            <PSupportWidget />
          </div>
          
        </div>

      </div>

    </div>
  </AreaContainer>
</template>

<script>

import PVolumeWidget from "../components/PVolumeWidget"
import POrdersWidget from "../components/POrdersWidget"
import PSupportWidget from "../components/PSupportWidget"
import PCompanyWidget from "../components/PCompanyWidget"
import PMessageWidget from "../components/PMessageWidget"
import PInventoryPooling from "../components/PInventoryPooling"
import PEnvironmentWidget from "../components/PEnvironmentWidget"
import PInventoryCommission from "../components/PInventoryCommission"

import { mapGetters } from "vuex"

export default {
  
  components: {
    PVolumeWidget,
    POrdersWidget,
    PSupportWidget,
    PCompanyWidget,
    PMessageWidget,
    PInventoryPooling,
    PEnvironmentWidget,
    PInventoryCommission
  },

  computed: {
    ...mapGetters(["customer", "location"])
  }

}

</script>
