import { render, staticRenderFns } from "./PCompanyWidget.vue?vue&type=template&id=1ee03a02"
import script from "./PCompanyWidget.vue?vue&type=script&lang=js"
export * from "./PCompanyWidget.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports