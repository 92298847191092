<template>
  <div>
    <div class="flex items-center justify-between">
      <div>
        <PWidgetHeader icon="balance-scale">
          <PTranslation k="PInventoryCommission.CommissionBalance" />
        </PWidgetHeader>
        <div class="mt-1 text-xs">
          <template v-if="source === 'External'">
            {{ $t("PInventoryCommission.InventoryAtSmartRetur", { customer: name1, company: name2 }) }}
          </template>
          <template v-else-if="source === 'Internal'">
            {{ $t("PInventoryCommission.InventoryAtCustomer", { location: name1, customer: name2 }) }}
          </template>
          <template v-else-if="source === 'Receipt'">
            {{ $t("PInventoryCommission.PalletReceipt", { location: location.name, customer: customer.name }) }}
          </template>
        </div>
      </div>
      <div class="flex items-center space-x-4">
        <div class="flex items-center space-x-2">
          <div class="w-3 h-3 bg-red-200"></div>
          <div class="text-sm font-medium">
            <PTranslation k="PInventoryCommission.Owes" />
          </div>
        </div>
        <div class="flex items-center space-x-2">
          <div class="w-3 h-3 bg-green-200"></div>
          <div class="text-sm font-medium">
            <PTranslation k="PInventoryCommission.Credit" />
          </div>
        </div>
      </div>
    </div>

    <PLoader
      class="mt-4"
      v-if="isLoading"
    />

    <template v-else>

      <table class="w-full mt-4 border-b border-gray-200">
        <thead>
          <tr>
            <td class="px-2 py-1 text-sm font-medium">
              <PTranslation k="Common.General.Product" />
            </td>

            <template v-if="config?.theme === 'swa'">
              <td class="w-40 px-2 py-1 text-sm font-medium text-center">
                <PTranslation k="PInventoryPooling.IncomingVolume" />
              </td>
              <td class="w-40 px-2 py-1 text-sm font-medium text-center">
                <PTranslation k="PInventoryPooling.Balance" />
              </td>
              <td class="w-40 px-2 py-1 text-sm font-medium text-center">
                <PTranslation k="PInventoryPooling.OutgoingVolume" />
              </td>
              <td class="w-40 px-2 py-1 text-sm font-medium text-center">
                <PTranslation k="PInventoryPooling.PhysicalBalance" />
              </td>
            </template>

            <template v-if="countries.length > 1">
              <td
                class="w-24 px-2 py-1 text-right"
                v-for="(country, index) in countries"
                :key="index"
              >
                <PFlag
                  class="inline-block w-6"
                  :country="country"
                />
              </td>
            </template>
            <td
              class="w-24 px-2 py-1 text-sm font-medium text-right"
              v-if="config?.theme !== 'swa'"
            >
              <PTranslation k="Common.General.Total" />
            </td>
          </tr>
        </thead>
        <tbody>
          <tr
            class="border-t border-gray-200 group hover:bg-orange-200"
            v-for="(product, i) in products"
            :key="i"
          >
            <td class="px-2 py-1">
              <div class="flex items-center space-x-2">
                <img
                  :src="`${assetsBaseUrl}/media.ashx?${product.productImage}&template=product-image-xsmall`"
                  alt=""
                />
                <div class="pl-2 text-sm">{{ product.productName }} - {{ product.qualityName }} {{ product.locationName
                  }}</div>
              </div>
            </td>

            <template v-if="config?.theme === 'swa'">
              <td class="px-2 py-1 text-xs text-center">{{ formatNumber(product.inTotal) }}</td>
              <td
                class="px-2 py-1 text-xs text-center"
                :class="{ 'green': product.total > 0, 'red': product.total < 0 }"
              >{{ formatNumber(product.total) }}</td>
              <td class="px-2 py-1 text-xs text-center">{{ formatNumber(product.outTotal) }}</td>
              <td
                class="px-2 py-1 text-xs text-center"
                :class="{ 'green': product.disp > 0, 'red': product.disp < 0 }"
              >{{ formatNumber(product.disp) }}</td>
            </template>

            <template v-if="countries.length > 1">
              <td
                class="px-2 py-1 text-xs text-right"
                :class="{ 'green': balance.quantity > 0, 'red': balance.quantity < 0 }"
                v-for="(balance, j) in product.balance"
                :key="j"
              >
                {{ formatNumber(balance.quantity) }}
              </td>
            </template>

            <td
              v-if="config?.theme !== 'swa'"
              class="px-2 py-1 text-xs text-right"
              :class="{
                'green': (location.showInternalStock && product.total < 0) || (!location.showInternalStock && product.total > 0),
                'red': (location.showInternalStock && product.total > 0) || (!location.showInternalStock && product.total < 0)
              }"
            >
              {{ formatNumber(product.total) }}
            </td>
          </tr>
        </tbody>
      </table>

    </template>

  </div>
</template>

<script>

import http from "@/http"
import { mapGetters } from "vuex"
import { find, forEach, get, keys, groupBy, sumBy } from "lodash"
import PWidgetHeader from "./PWidgetHeader"
import { getConfig } from "@/config"

var config = getConfig()

export default {

  components: {
    PWidgetHeader
  },

  data () {
    return {
      products: [],
      external: [],
      countries: [],
      source: "",
      isLoading: false,
      config: config
    }
  },

  computed: {

    ...mapGetters(["status", "customer", "location"]),

    assetsBaseUrl () {
      return this.$appConfig.assetsBaseUrl
    }

  },

  methods: {

    formatNumber (number) {
      return new Intl.NumberFormat(this.$i18n.locale.replace('-sw', '')).format(Math.abs(number))
    },

    getBalance (balances, countryId, productId, qualityId) {

      const match = find(balances, e =>
        e.countryId === countryId &&
        e.productId === productId &&
        e.qualityId === qualityId
      )

      let res = {
        quantityIn: 0,
        quantityOut: 0,
        quantity: 0,
      }

      if (match) {
        res.quantityIn = match.quantityIn
        res.quantityOut = match.quantityOut
        res.quantity = match.quantity
      }

      return res
    },

    async init () {

      if (this.location.isCommission) {

        this.isLoading = true

        try {

          const balance = await http.get("InventoryCommission", {
            params: {
              locationId: this.location.id,
              withInterCompany: true
            }
          })

          const data = get(balance, "balance", [])

          const products = get(balance, "products", [])

          const groups = groupBy(data, e => e.countryId)
          const countries = keys(groups)

          forEach(products, product => {

            product.balance = []

            product.inTotal = 0
            product.outTotal = 0
            product.total = 0
            product.disp = 0

            forEach(countries, countryId => {
              product.balance.push(
                this.getBalance(
                  data,
                  countryId,
                  product.productId,
                  product.qualityId)
              )

              product.inTotal = sumBy(product.balance, "quantityIn")
              product.outTotal = sumBy(product.balance, "quantityOut")
              product.total = sumBy(product.balance, "quantity")
              product.disp = product.inTotal + product.total - product.outTotal
            })
          })

          this.source = get(balance, "source")
          this.name1 = get(balance, "name1")
          this.name2 = get(balance, "name2")
          this.products = products
          this.countries = countries

        } catch (error) {
          this.$appInsights.trackException({ exception: error })
        }

        this.isLoading = false
      }
    }
  },

  async created () {
    await this.init()
  }

}
</script>

<style scoped>

  .green {
    @apply bg-green-100 text-green-700 border-b border-green-200;
  }

  .red {
    @apply bg-red-200 text-red-700 border-b border-green-200;
  }

</style>